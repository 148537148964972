$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
@import "../node_modules/bootstrap/scss/bootstrap";
// $fa-font-path: "../node_modules/font-awesome/fonts";
// @import "../node_modules/font-awesome/scss/font-awesome";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/leaflet/dist/leaflet.css";
//@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
//@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@font-face {
  font-family: 'MoonHouse';
  src: url('./assets/fonts/moonhouse.eot');
  src: url('./assets/fonts/moonhouse.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/moonhouse.ttf') format('truetype');
}

@import "app/theme/styles/variables";
@import "app/theme/styles/mixins";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/base";

@-webkit-keyframes trans1 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-250px,0,0);
  }
}

@-moz-keyframes trans1 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-250px,0,0);
  }
}

@keyframes trans1 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-250px,0,0);
  }
}

@-webkit-keyframes trans2 {
  from {
    transform: translate3d(-160px,0,0);
  }

  to {
    transform: translate3d(53px,0,0);
  }
}

@-moz-keyframes trans2 {
  from {
    transform: translate3d(-160px,0,0);
  }

  to {
    transform: translate3d(53px,0,0);
  }
}

@keyframes trans2 {
  from {
    transform: translate3d(-160px,0,0);
  }

  to {
    transform: translate3d(53px,0,0);
  }
}

@-webkit-keyframes trans3 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-220px,0,0);
  }
}

@-moz-keyframes trans3 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-220px,0,0);
  }
}

@keyframes trans3 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-220px,0,0);
  }
}
@media only screen and (max-width:600px){
    .breadcrumb1 li {
        display: inline-block;
        color: white;
        cursor: pointer;
        font-size: 13px;
    }
}

.button-66 {
    background-color: #0060eb;
    border-radius: 4px;
    border: 0;
    box-shadow: rgba(1, 60, 136, 0.5) 0 -1px 3px 0 inset, rgba(0, 44, 97, 0.1) 0 3px 6px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inherit;
    /* font-family: "Space Grotesk", -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
    /* font-size: 15px; */
    /* font-weight: 700; */
    line-height: -10px;
    margin: -3% 0% 9% 0%;
    /* height: 48px; */
    min-width: 120px;
    padding: 12px;
    position: relative;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.button-66:hover {
    background-color: #00bffa;
    transform: translateY(-2px);
}

@media (min-width: 768px) {
    .button-66 {
        /*padding: 16px 44px;*/
        min-width: 150px;
    }
}
body, container, div, container-fluid, p {
    font-family: Poppins;
    letter-spacing: 0.2px;
}
html {
  min-height: 100%;
  overflow-y: scroll;
}
p {
    text-align:left;
    font-size: 16px;
    /*letter-spacing: 0.5px;*/
    line-height: 2;
    /*text-align: justify;*/
}
body {
  min-height: 100%;
  background-color: white;
}
@media only screen and (max-width: 600px) {
  body, container, div {
  }
}
.section-title {
    margin-bottom: 0px;
}
body {
  overflow-x: hidden;
  max-width: 100%;
}
.breadcrumb1 {
    position: relative;
    background: url('assets/img/bg.jpg') center center/cover;
    padding: 6px;
    margin-bottom: 5%;
    margin-top: 9%;
}

.breadcrumb1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(2 0 36 / 47%);
    z-index: -1;
}

.breadcrumb1 a {
    color: white;
    text-decoration: none;
}

.breadcrumb1 ul {
    list-style-type: none;
    margin-top: 2%;
}

.breadcrumb1 li {
    display: inline-block;
    color: white;
    cursor: pointer;
    font-size: 17px;
}

.breadcrumb1 div {
    font-size: 20px;
    color: white;
}
@media only screen and (max-width: 600px) {
    .p-component, .p-component * {
        box-sizing: border-box;
        margin-left: 3% !important;
        width: 81%;
    }

    .breadcrumb {
        padding: 0.5rem 1rem;
        font-size: 10px;
    }
}
@media only screen and (max-width: 600px) {
  .breadcrumb {
    padding: 0.5rem 1rem;
    font-size: 11px;
    padding-left: 1rem;
    max-height: 35px;
    width: 312px;
    margin-left: 0rem;
    margin-right: 42rem;




  }
  }
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  opacity: 0.5;
  transition: opacity 0.6s ease;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffc2b3;
  background-clip: padding-box;
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
}

    .nav-link {
      color: #000;
    }
body {
  font-family: Poppins;
  color: #212529;
}
.about p {
  font-size: 15px;
  color: #5a6570;
}
.loader-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.5s, opacity 0.3s linear;
  -moz-transition: visibility 0.5s, opacity 0.3s linear;
  transition: visibility 0.5s, opacity 0.3s linear;

  &.hide {
    visibility: hidden;
    opacity: 0;
  }

  .portfolio .portfolio-wrap .portfolio-links {
    /* opacity: 0; */
    left: 0;
    right: 0;
    top: calc(50% - 32px);
    text-align: center;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
  }

  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 0 35px 0;
    list-style: none;
    text-align: center;
  }

  .portfolio .portfolio-wrap img {
    transition: 0.3s;
    position: relative;
    z-index: 1;
  }

  .loader-container {
    height: 100%;
    width: 100%;
    display: table;

    .loader-inner {
      vertical-align: middle;
      height: 100%;
      display: table-cell;

      .loader {
        width: 80px;
        margin: auto;
        position: relative;
        height: 60px;

        .mask {
          position: absolute;
          overflow: hidden;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;

          .plane {
            background: #DD1B16;
            width: 400%;
            height: 100%;
            position: absolute;
          }
        }

        #top {
          width: 53px;
          height: 15px;
          left: 20px;
          -webkit-transform: skew(-15deg, 0);
          -moz-transform: skew(-15deg, 0);
          transform: skew(-15deg, 0);

          .plane {
            -webkit-animation: trans1 1.3s ease-in infinite 0s backwards;
            -moz-animation: trans1 1.3s ease-in infinite 0s backwards;
            animation: trans1 1.3s ease-in infinite 0s backwards;
          }
        }

        #middle {
          width: 33px;
          height: 15px;
          left: 20px;
          top: 15px;
          -webkit-transform: skew(-15deg, 40deg);
          -moz-transform: skew(-15deg, 40deg);
          transform: skew(-15deg, 40deg);

          .plane {
            transform: translate3d(0px,0,0);
            background: #DD1B16;
            -webkit-animation: trans2 1.3s linear infinite 0.3s backwards;
            -moz-animation: trans2 1.3s linear infinite 0.3s backwards;
            animation: trans2 1.3s linear infinite 0.3s backwards;
          }
        }

        #bottom {
          width: 53px;
          height: 15px;
          top: 30px;
          -webkit-transform: skew(-15deg, 0);
          -moz-transform: skew(-15deg, 0);
          transform: skew(-15deg, 0);

          .plane {
            -webkit-animation: trans3 1.3s ease-out infinite 0.7s backwards;
            -moz-animation: trans3 1.3s ease-out infinite 0.7s backwards;
            animation: trans3 1.3s ease-out infinite 0.7s backwards;
          }
        }

        p.loader-text {
          color: #000;
          position: absolute;
          top: 42px;
          font-family: 'MoonHouse';
          font-size: 16px;

          span {
            color: #DD1B16;
          }
        }
      }
    }
  }
}
.p-toast-bottom-center {
    bottom: 352px;
    left: 25%;
    transform: translateX(-26%);
    width: -78px;
    color: red;
     height: 100px; 
    /*background-color: white;*/
    padding: 15px;
}

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none #ffffff;
      border-radius: 0px;

      &:hover {
        background: #cccccc;
      }

      &:active {
        background: #888888;
      }
    }

    ::-webkit-scrollbar-track {
      background: #666666;
      border: 0px none #ffffff;
      border-radius: 0px;

      &:hover {
        background: #666666;
      }

      &:active {
        background: #333333;
      }
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    .p-autocomplete {
      width: 225px;
      height: 25px;
      margin-top: 0.9rem;
    }

    .p-inputtext {
      margin: 0;
      width: 249px !important;
      background-size: 18px 19px !important;
      background-position: right !important;
      background-image: url('assets/icons/search.gif') !important;
      background-repeat: no-repeat !important;
    }
.card_s {
  display: none;
}
.card_p {
  display: none;
}
.button-1 {
  display: none;
}
@media only screen and (max-width:600px) {
  .mobile_menu {
    display: none;
  }

  .card_s {
    margin-top: -18%;
    cursor: pointer;
    display: inherit;
  }
  .card_p {
    margin-top: -11%;
    cursor: pointer;
    display: inherit;
  }
  .button-1 {
    display: inherit;
    /*background-color: #0e0087;*/
    border: 0;
    border: 1px solid #0e0087;
    border-radius: 56px;
    color: black;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    outline: 0;
    padding: 4px 21px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 1rem;
  }

  .button-1:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 70%;
    padding: 10px;
  }


  .button-1:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }
  .button_s {
    display: inherit;
    /* background-color: #0e0087; */
    border: 0;
    border-radius: 56px;
    border: 1px solid #0e0087;
    color: black;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    outline: 0;
    padding: 4px 21px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 1rem
  }
  .button_s:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 70%;
    padding: 10px;
  }


  .button_s:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }
}

@media only screen and (max-width: 600px) {
    .p-toast-message {
        max-width: 80vw; /* Adjust the maximum width as needed */
        word-wrap: break-word;
    }
}
